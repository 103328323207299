// Core
import React, { FC, ReactElement } from "react";

// Assets
import { shield } from "@cpsq/ui-components";

// Stylesheets
import "./dashboard.scss";
import "./dashboard-responsive.scss";

// Components
import { Typography } from "@material-ui/core";

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps, withRouter } from "react-router-dom";

// Utils
import { useAuth0 } from "@cpsq/auth-frontend";

const Dashboard: FC<RouteComponentProps> = (): ReactElement => {
  const { isInternalAdmin } = useAuth0();
  const clickCPSQCard = (): void => {
    let targetUrl = window.location.origin;

    process.env.PUBLIC_URL
      ? (targetUrl += "/admin/centre-dashboard/")
      : (targetUrl += "/centre-dashboard/");

    window.location.href = targetUrl;
  };

  const clickCentreCard = (): void => {
    let targetUrl = window.location.origin;

    process.env.PUBLIC_URL
      ? (targetUrl += "/identity/centres/")
      : (targetUrl += "/centres/");

    window.location.href = targetUrl;
  };

  const clickTestUsageCard = (): void => {
    let targetUrl = window.location.origin;

    process.env.PUBLIC_URL
      ? (targetUrl += "/admin/test-usage/")
      : (targetUrl += "/test-usage/");

    window.location.href = targetUrl;
  };

  const getWelcomeMessage = (): ReactElement => {
    return isInternalAdmin ? (
      <Typography variant="h4" className="gamma">
        Welcome,
        <br />
        <br />
        You can manage the centres and view their test usage.
      </Typography>
    ) : (
      <Typography variant="h4" className="gamma">
        Welcome,
        <br />
        <br />
        You can manage your assessment products provided by{" "}
        <span className="italic">Cambridge Assessment</span>.
      </Typography>
    );
  };

  const getProducts = (): ReactElement => {
    return isInternalAdmin ? (
      <div className="products">
        <section className="product-card" onClick={clickCentreCard}>
          <FontAwesomeIcon icon="landmark" size="2x" />
          <Typography variant="h4" className="gamma bold">
            Centres
          </Typography>
          <Typography variant="body1" className="gamma">
            Manage centres
          </Typography>
        </section>
        <section className="product-card" onClick={clickTestUsageCard}>
          <FontAwesomeIcon icon="chart-line" size="2x" />
          <Typography variant="h4" className="gamma bold">
            Test usage
          </Typography>
          <Typography variant="body1" className="gamma">
            View centres test usage
          </Typography>
        </section>
      </div>
    ) : (
      <div className="products">
        <section className="product-card" onClick={clickCPSQCard}>
          <FontAwesomeIcon icon="theater-masks" size="2x" />
          <Typography variant="h4" className="gamma bold">
            CPSQ
          </Typography>
          <Typography variant="body1" className="gamma">
            Cambridge Personal Styles Questionnaire
          </Typography>
        </section>
      </div>
    );
  };

  return (
    <section className="welcome">
      <div className="hero breakout">
        <div className="column-one">{getWelcomeMessage()}</div>
        <div className="column-two">
          <img src={shield} alt="Logo" className="logo" />
        </div>
      </div>
      {getProducts()}
    </section>
  );
};

export default withRouter(Dashboard);
