// Core
import React, { ReactElement, useCallback, useEffect } from "react";

// Components
import { Button } from "@cambridgeassessment/cambridge-ui";
import { shield, StandardLayout } from "@cpsq/ui-components";
import { Typography } from "@material-ui/core";

// Stylesheets
import "./home.scss";
import "./home-responsive.scss";

// Utils
import { useAuth0 } from "@cpsq/auth-frontend";

// Vendor
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  history: History;
}

const Home = (props: Props): ReactElement => {
  const { isAuthenticated, loading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    document.body.className = "welcome";

    if (!loading) {
      if (isAuthenticated) {
        props.history.push("/dashboard/");
      }
    }

    return (): void => {
      document.body.className = "";
    };
  });

  const clickLogin = useCallback(() => {
    loginWithRedirect({});
  }, [loginWithRedirect]);

  return (
    <StandardLayout>
      <div className="welcome">
        <div className="welcome-card">
          <div className="logo-container">
            <img src={shield} alt="Logo" className="logo" />
          </div>
          <Typography variant="h4" gutterBottom className="gamma">
            Welcome,
            <br />
            <br />
            <span className="bold">
              Please log in to manage your assessment products provided by{" "}
              <span className="italic">Cambridge Assessment</span>
            </span>
            .
          </Typography>
          <div className="button-container">
            <Button
              color="primary"
              onClick={clickLogin}
              data-testid="Go to Log In"
            >
              Go to Log In
            </Button>
          </div>
        </div>
      </div>
    </StandardLayout>
  );
};

export default withRouter(Home);
