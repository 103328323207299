// Core
import React from "react";

// Components
import App from "./App";

// Utils
import { Auth0Provider } from "@cpsq/auth-frontend";
import { auth0Config, gtmConfig } from "./utils/config";

// Vendor
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

// Styling
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import {
  createGlobalStyle as GlobalStyle,
  mainTheme
} from "@cambridgeassessment/cambridge-ui";

const tagManagerArgs = {
  gtmId: gtmConfig.gtmId
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Auth0Provider
    domain={auth0Config.domain}
    client_id={auth0Config.clientId}
    redirect_uri={auth0Config.redirectUri}
    audience={auth0Config.audience}
  >
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById("root")
);
