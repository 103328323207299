import { getRedirectUri } from "@cpsq/auth-frontend";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _auth0_: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _gtm_: any;
  }
}

export const auth0Config = {
  clientId: window._auth0_.AUTH0_CLIENT_ID as string,
  audience: window._auth0_.AUTH0_AUDIENCE as string,
  domain: window._auth0_.AUTH0_DOMAIN as string,
  redirectUri: getRedirectUri()
};

export const gtmConfig = {
  gtmId: window._gtm_.GTM_ID as string
};
