// Utils
import { Api as ApiUtils } from "@cpsq/common-utils";

export default class Api {
  static backend = process.env.REACT_APP_BACKEND;
  static identityBackend = process.env.REACT_APP_IDENTITY_BACKEND;
  static utils = new ApiUtils(Api.backend);
  static getAuthToken: () => Promise<string>;

  static async getCentre(
    centreId: string,
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      `centres/${centreId}`,
      callback,
      errorCallback,
      await Api.getAuthToken(),
      Api.identityBackend
    );
  }

  static async getCentreFeatures(
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      "centre-features",
      callback,
      errorCallback,
      await Api.getAuthToken(),
      Api.identityBackend
    );
  }
}
