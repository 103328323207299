// Core
import React, { ReactElement, useEffect } from "react";

// Components
import { Auth0Redirect, PrivateRoute } from "@cpsq/auth-frontend";
import { AuthenticatedLayout } from "@cpsq/ui-components";

// Pages
import Dashboard from "./pages/dashboard/dashboard";
import Home from "./pages/home/home";

// Stylesheets
import "./assets/stylesheets/application.scss";

// Utils
import { Session, useAuth0, useToken } from "@cpsq/auth-frontend";
import Api from "./utils/api";

// Vendor
import {
  faBars,
  faChartBar,
  faChartLine,
  faCog,
  faExternalLinkAlt,
  faHome,
  faLandmark,
  faSignInAlt,
  faSignOutAlt,
  faTasks,
  faTheaterMasks,
  faTimes,
  faUserCircle,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

library.add(
  faBars,
  faChartBar,
  faChartLine,
  faCog,
  faExternalLinkAlt,
  faHome,
  faLandmark,
  faSignInAlt,
  faSignOutAlt,
  faTasks,
  faTheaterMasks,
  faTimes,
  faUserCircle,
  faInfoCircle
);

const App = (): ReactElement => {
  const {
    isAuthenticated,
    isInternalAdmin,
    loading,
    loginWithRedirect,
    logout,
    getTokenSilently
  } = useAuth0();
  useEffect(() => {
    if (!loading) {
      Api.getAuthToken = getTokenSilently;
    }
  }, [loading, getTokenSilently]);
  const serviceName = "welcome";
  const token = useToken();

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          exact
          path="/callback/"
          render={(props): ReactElement => (
            <Auth0Redirect {...props} serviceName={serviceName} />
          )}
        />
        <Route
          render={(props): ReactElement => {
            return (
              <AuthenticatedLayout
                getCentre={Api.getCentre}
                getCentreFeatures={Api.getCentreFeatures}
                heading=""
                isAuthenticated={isAuthenticated}
                isInternalAdmin={isInternalAdmin}
                loading={loading}
                serviceName={serviceName}
                Session={Session}
                signIn={loginWithRedirect}
                signOut={logout}
                token={token}
                {...props}
              >
                <PrivateRoute path="/dashboard/" component={Dashboard} />
              </AuthenticatedLayout>
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default App;
